const DOM = Object.freeze({
  videoSelector: '[data-video]',
  videoPlayButtonSelector: '[data-video-play]',
  videoPlayerSelector: '[data-video-player]',
  videoPlaceholderImageSelector: '[data-video-placeholder]',
  api: 'https://www.youtube.com/iframe_api',
  cookiebotScriptSelector: '#Cookiebot',
  videoCookiebotPlaceholderSelector: 'data-cookiebot-video-placeholder',
  youtubeVideoSelector: '[data-video-youtube]',
  videoSrcSelector: '[data-video-src]',
  videoOverlay: '[data-video-overlay]'
});

const handleLoadVideo = (id, element) => {
  const $youtubeVideo = document.querySelector(DOM.youtubeVideoSelector);
  const players = [];

  const onPlayerReady = event => {
    event.target.playVideo();
  };

  const createScriptTag = () => {
    let tag = document.createElement('script');
    tag.src = DOM.api;
    return tag;
  };

  const loadAPI = () => {
    if ($youtubeVideo) {
      if (typeof YT == 'undefined' || typeof YT.Player == 'undefined') {
        let tag = createScriptTag();
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        return false;
      } else {
        return true;
      }
    }
  };

  const loadVideo = (videoId, playerElement) => {
    if ($youtubeVideo) {
      const player = new YT.Player(playerElement, {
        height: '100%',
        width: '100%',
        videoId: videoId,

        events: {
          onReady: onPlayerReady,
        },
      });

      const iframe = player.g;
      if (iframe) {
        iframe.setAttribute('data-cookieconsent', 'marketing');
      }
      players.push(player);
    }
  };

  const $playerElement = element.querySelector(DOM.videoPlayerSelector);
  const $cookiebotScript = document.querySelector(DOM.cookiebotScriptSelector);

  if ($cookiebotScript && typeof Cookiebot !== 'undefined' && Cookiebot?.consent?.marketing == true) {
    const apiIsLoaded = loadAPI();

    if (apiIsLoaded) {
      loadVideo(id, $playerElement)
    } else {
      (window.onYouTubePlayerAPIReady = () => {
        loadVideo(id, $playerElement)
      });
    }
  }
};

const hidePlayButtonAndImage = element => {
  const $cookiebotScript = document.querySelector(DOM.cookiebotScriptSelector);

  if ($cookiebotScript && typeof Cookiebot !== 'undefined' && Cookiebot?.consent?.marketing == true) {
    const videoOverlay = element.querySelector(DOM.videoOverlay);
    videoOverlay ? videoOverlay.classList.remove('video-overlay') : null;

    const playButton = element.querySelector(DOM.videoPlayButtonSelector);
    playButton ? playButton.classList.add('d-none') : null;

    const thumbnail = element.querySelector(".js-video-image");
    thumbnail ? thumbnail.classList.add('d-none') : null;
  }
};

export const video = {
  init() {
    const $videoContainers = [...document.querySelectorAll(DOM.videoSelector)];

    if ($videoContainers.length) {
      const body = document.querySelector('body');

      body.addEventListener('click', event => {
        const element = event.target.closest(DOM.videoSelector);
        if (!element) return;

        const id = element.dataset.videoId;
        if (!id) return;

        hidePlayButtonAndImage(element);
        handleLoadVideo(id, element);
      });
    }
  }
}
