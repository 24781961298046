export const epiformsGA = {
  init() {
    window.dataLayer = window.dataLayer || [];
    const episerverForm = document.querySelector('[data-epiform-redirect-url]');
    let formIsSubmitted = false;
    let currentFormId = '';

    if (episerverForm) {
      window.onbeforeunload = function () {
        var searchObject = {
          'event': 'Epiform_Submitted',
          'currentFormId': currentFormId
        };

        if (!eventIsInDataLayer(searchObject)) {
          if (typeof $$epiforms !== 'undefined' && formIsSubmitted) {
            dataLayer.push({
              'event': 'Epiform_Submitted',
              'currentFormId': currentFormId
            });
          }
        }
      };
    }

    if (typeof $$epiforms !== 'undefined') {
      $$epiforms(document).ready(function myfunction() {
        $$epiforms(".EPiServerForms").on("formsSubmitted", function () {
          formIsSubmitted = true;
          currentFormId = $$epiforms(this).get(0).getAttribute('id');

          dataLayer.push({
            'event': 'Epiform_Submitted',
            'currentFormId': currentFormId
          });
        });
      });
    }

    function eventIsInDataLayer(searchObject) {
      var dataLayerName = 'dataLayer';

      function getValueForObjectString(obj, key) {
        return key.split(".").reduce(function (o, x) {
          return (typeof o == "undefined" || o === null) ? o : o[x];
        }, obj);
      };

      return window[dataLayerName].filter(function (obj) {
        var found = false;
        var prop;

        for (prop in searchObject) {
          if (obj[prop] == searchObject[prop] || getValueForObjectString(obj, prop) == searchObject[prop]) {
            found = true;
          }
        }
        return found;
      }).length > 0;
    }
  },
};
