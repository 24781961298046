import '../lib/js-cookie-2.2.0';

export const modal = {
  init() {
    var modals = [...document.querySelectorAll('.js-modal')];
    if (modals.length) {
      modals.forEach(m => {
        if (m.dataset.open === "1" && !(Cookies.get('cookie-popup-dismissed'))) {
          m.classList.add('modal--show');
        }
      });
    }

    const modalCloseBtns = [...document.querySelectorAll('.js-modal-close')];
    if (modalCloseBtns.length) {
      modalCloseBtns.forEach(btn => {
        btn.addEventListener('click', this.onModalCloseClick);
      });
    }
  },

  onModalCloseClick(e) {
    e.preventDefault();
    var modal = e.currentTarget.closest('.js-modal');
    modal.classList.remove('modal--show');
    if (modal.dataset.setcookie === "1") {
      var expires = 365;
      Cookies.set('cookie-popup-dismissed', 'true', { expires: expires, path: '/' });
    }
  },
};
