const init = () => {

  const qtyPicker = [...document.querySelectorAll('.js-qty-picker')];
  if (qtyPicker.length) {
    qtyPicker.forEach(picker => {
      const removeButton = picker.querySelector('.js-qty-button-remove');
      const addButton = picker.querySelector('.js-qty-button-add');
      const input = picker.querySelector('.js-qty-input');
      
      addButton.addEventListener('click', function(e) {
          input.value = parseInt(input.value) + 1;
      });

      removeButton.addEventListener('click', function (e) {
        var newValue = parseInt(input.value) - 1;
        if (newValue < 1) {
          newValue = 1;
        }
        input.value = newValue;
      });
    });
  }
}

const qtyChange = {
  init,
};

export default qtyChange;
