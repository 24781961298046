const isSortable = (table) => {
  if (!table) {
    return false;
  }

  return table.dataset.sortable === 'true';
}

const getHeaders = (table) => {
  return table.querySelectorAll("th");
}

const getCellValue = (tr, index) => tr.children[index].innerText || tr.children[index].textContent;

const comparer = (index, asc) => (firstCell, secondCell) => ((value1, value2) =>
  value1 !== '' && value2 !== '' && !isNaN(value1) && !isNaN(value2) ? value1 - value2 : value1.toString().localeCompare(value2)
)(getCellValue(asc ? firstCell : secondCell, index), getCellValue(asc ? secondCell : firstCell, index));

export default function initTables() {
  const SELECTOR_TABLE = "[data-trigger='table']";

  const tables = [...document.querySelectorAll(SELECTOR_TABLE)];

  tables.forEach(table => {
    const body = table.querySelector("tbody");

    if (isSortable(table)) {
      const headers = getHeaders(table);
      headers.forEach(header => {
        header.addEventListener("click", () => {
          Array.from(body.querySelectorAll('tr'))
            .sort(comparer(Array.from(header.parentNode.children).indexOf(header), header.asc = !header.asc))
            .forEach(tr => body.appendChild(tr));
        });
      });
    }
  });
}
