export const lists = {
  init() {
    const listBtn = document.querySelector('.js-button-list');
    if (listBtn) {
      listBtn.addEventListener('click', this.onListBtnClick);
    }
  },
  onListBtnClick(e) {
    e.preventDefault();
    var btn = e.target;
    var page = parseInt(btn.dataset.page);
    var lastPage = parseInt(btn.dataset.lastpage);

    fetch(`${window.location.href}?page=${page}`,
      {
        credentials: 'include',
        headers: {
          'Accept': 'text/html',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).then(res => res.json())
      .then(data => {
        if (data.View) {
          var list = document.querySelector('.js-list');
          var div = document.createElement('div');
          div.innerHTML = data.View;

          var liList = [...div.querySelectorAll('li')];
          liList.forEach(li => {
            list.appendChild(li);
          });
        }
        if (page >= lastPage) {
          btn.remove();
        } else {
          page++;
          btn.dataset.page = page;
        }

      });
  }
};
