const API =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyCcw-7xTblpeYOTSRao5M9k3SiLtLyHHd8';
const MarkerCluserAPI =
  'https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js';
const googleMapsSelector = '.js-googlemap';
const markers = [];
const showTextInput = document.querySelector('.js-show-text');
const pinImage = {
  path: 'M8,0.2c-3.1,0-5.7,2.6-5.7,5.7c0,1.5,0.6,2.8,1.5,3.8c0.3,0.3,1.1,0.9,1.2,1c1.5,1,2.5,2.8,2.5,4.7C7.5,15.8,7.7,16,8,16c0.3,0,0.5-0.2,0.5-0.6c0-2,1-3.7,2.5-4.7c0,0,0.9-0.6,1.2-1c0.9-1,1.5-2.4,1.5-3.8C13.7,2.7,11.1,0.2,8,0.2',
  scale: 2,
  fillOpacity: 1,
  fillColor: '#006892',
  strokeColor: '#006892'
};
const pinImageHighlighted = {
  path:
    'M8,0.2c-3.1,0-5.7,2.6-5.7,5.7c0,1.5,0.6,2.8,1.5,3.8c0.3,0.3,1.1,0.9,1.2,1c1.5,1,2.5,2.8,2.5,4.7C7.5,15.8,7.7,16,8,16c0.3,0,0.5-0.2,0.5-0.6c0-2,1-3.7,2.5-4.7c0,0,0.9-0.6,1.2-1c0.9-1,1.5-2.4,1.5-3.8C13.7,2.7,11.1,0.2,8,0.2',
  scale: 2,
  fillOpacity: 1,
  fillColor: '#34e0a1',
  strokeColor: '#34e0a1'
};

export const init = () => {
  const googleMaps = [...document.querySelectorAll(googleMapsSelector)];
  if (googleMaps.length) {
    const timeoutTime = 1250;
    loadAPI();
    setTimeout(function () {
      initMap();
    }, timeoutTime);
  }
};

const loadAPI = () => {
  if (
    typeof google == 'undefined' ||
    typeof google.maps == 'undefined' ||
    typeof google.maps.Map == 'undefined'
  ) {
    var tag = document.createElement('script');
    tag.src = API;
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var clusterTag = document.createElement('script');
    clusterTag.src = MarkerCluserAPI;
    firstScriptTag.parentNode.insertBefore(clusterTag, firstScriptTag);
  }
};

const initMap = () => {
  const googleMaps = [...document.querySelectorAll(googleMapsSelector)];

  if (googleMaps.length) {
    for (var i = 0; i < googleMaps.length; i++) {
      var googleMap = googleMaps[i];

      var uluru = {
        lat: parseFloat(googleMap.dataset.lat),
        lng: parseFloat(googleMap.dataset.lng),
      };

      var infowindow = new google.maps.InfoWindow();

      var extraMarkers = [
        ...googleMap.querySelectorAll('.js-marker-locations'),
      ];

      var map = new google.maps.Map(googleMap.querySelector('.js-map'), {
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        center: uluru,
        mapTypeControl: false,
        streetViewControl: false,
        zoom: parseInt(googleMap.dataset.zoom),
        maxZoom: 18,
        mapTypeControl: false,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        styles: getStyles()
      });


      if (extraMarkers.length) {
        const markerLocations = [];

        markerLocations.push({
          lat: parseFloat(googleMap.dataset.lat),
          lng: parseFloat(googleMap.dataset.lng),
          title: googleMap.dataset.title,
          url: googleMap.dataset.url,
          phone: googleMap.dataset.phone,
          description: googleMap.dataset.description,
          highLighted: true
        })
        for (var i = 0; i < extraMarkers.length; i++) {
      
          markerLocations.push({
            lat: parseFloat(extraMarkers[i].dataset.lat),
            lng: parseFloat(extraMarkers[i].dataset.lng),
            title: extraMarkers[i].dataset.title,
            url: extraMarkers[i].dataset.url,
            phone: extraMarkers[i].dataset.phone,
            description: extraMarkers[i].dataset.description,
            highLighted: false
          });
        }

        for (var j = 0; j < markerLocations.length; j++) {
          placeMarker(map, infowindow, markerLocations[j]);
        }

        var clusterOptions = {
          imagePath: "/Static/images/markers/icon-marker",
          gridSize: 36,
          maxZoom: 18
        }

        new MarkerClusterer(map, markers, clusterOptions);
      }
    }
  }
};

function placeMarker(map, infowindow, loc) {
  const marker = new google.maps.Marker({
    position: { lat: loc.lat, lng: loc.lng },
    map: map,
    title: loc.title,
    icon: loc.highLighted ? pinImageHighlighted : pinImage
  });
  markers.push(marker);
  if (loc.title) {
    google.maps.event.addListener(marker, 'click', function () {
      infowindow.close();
      infowindow.setContent(
        `<div class="googlemaps__infowindow"><h3 class="text-medium-blue text-xl">${loc.title}</h3>
    <div class="d-flex justify-content-between">
        <div>
          <p class="text-base"><span class="d-block mb-3">${loc.description}</span>
              <span class="d-block mb-2"> ${loc.phone}</span>
            </p></div>
        <div class="mt-auto pb-3">
            <a href="${loc.url}" class="button button-reset button-googlemap">${showTextInput.value}</a>
        </div></div>  </div>`,
      );
      infowindow.open(map, marker);
      setTimeout(function(e) {
        
        var btn = document.querySelector('.gm-ui-hover-effect');
        var span = document.createElement('span');
        var span2 = document.createElement('span');
        btn.classList.add('googlemaps__close');
        btn.innerText = '';
        btn.appendChild(span);
        btn.appendChild(span2);
      }, 1);
    });
  }
}

function getStyles() {
  return [
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [{ "visibility": "off" }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [{ "visibility": "off" }]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [{ "color": "#d0d0d0" }, { "lightness": 17 }]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [{ "color": "#d0d0d0" }, { "lightness": 29 }, { "weight": 0.2 }]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [{ "color": "#d0d0d0" }, { "lightness": 18 }]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [{ "color": "#d0d0d0" }, { "lightness": 16 }]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [{ "color": "#fff" }],
    },
    {
      "elementType": "labels.icon",
      "stylers": [{ "visibility": "off" }]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [{ "hue": "#727D82" }, { "lightness": -30 }, { "saturation": -80 }]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [{ "visibility": "simplified" }, { "color": "#e1e1e1" }]
    }
  ]
}
