import '../lib/splide.min';

export const splideSlider = {
  splideSliderMain: document.querySelector('.js-splideslider'),
  splideSliderThumbnails: document.querySelector('.js-splideslider-secondary'),
  sliderBlocks: [...document.querySelectorAll('.js-sliderblock')],
  setImage(index) {
    const classSelector = `.js-image-${index}`;
    const images = [...document.querySelectorAll(classSelector)];
    images.forEach(image => {
      if (image.classList.contains('d-none')) {
        image.classList.remove('d-none');
        image.src = image.dataset.src;
      }
    });
  },
  init() {
    if (this.splideSliderMain && this.splideSliderThumbnails) {
      var slider = new Splide(this.splideSliderMain,
        {
          type: 'fade',
          pagination: false,
          arrows: true,
          cover: true,
          rewind: true
        });

      var sliderSecondary = new Splide(this.splideSliderThumbnails,
        {
          fixedWidth: 100,
          height: 66,
          gap: 10,
          cover: true,
          isNavigation: true,
          pagination: false,
          arrows: false,
        }).mount();

      slider.sync(sliderSecondary).mount();
      slider.on('move', this.setImage);
    }

    if (this.sliderBlocks.length) {
      this.sliderBlocks.forEach(slide => {
        const itemsInSlide = slide.querySelectorAll('li').length;
        const interval = parseInt(slide.dataset.interval);
        const moreThanOneSlide = itemsInSlide > 1;

        let splide = new Splide(slide, {
          type: 'loop',
          pagination: false,
          arrows: moreThanOneSlide,
          cover: true,
          rewind: moreThanOneSlide,
          drag: moreThanOneSlide,
          autoplay :moreThanOneSlide,
          interval: interval
        }).mount();

        splide.on('move', this.setImage);
      });
    }
  }
}
