const init = () => {
  document.addEventListener('click', e => onOutSideDropdownClick(e));
  document.addEventListener('keyup', e => onEscBtnClick(e));

  for (var i = 0; i < a11yToggles.length; i++) {
    a11yToggles[i].addEventListener('click', collapseAll);
  }
};

const a11yToggles = Array.prototype.slice.call(
  document.querySelectorAll('[data-a11y-toggle]:not(.js-ignore-dropdown-close)'),
);

const collapseAll = e => {
  var dropDowns = a11yToggles.filter(function (toggle) {
    return toggle !== e.currentTarget;
  });

  if (dropDowns) {
    for (var i = 0; i < dropDowns.length; i++) {
      closeDropDown(dropDowns[i]);
    }
  }
};

const onOutSideDropdownClick = e => {
  if (
    e.target.closest('.js-dropdown '))
    return;

  closeDropDowns(true, e);
};

const onEscBtnClick = e => {
  var escKeyCode = 27;
  if (e.keyCode === escKeyCode) {
    closeDropDowns(true, e);
  }
};

const closeDropDowns = (focus, e) => {
  const dropDownButtons = document.querySelectorAll('.js-dropdown-button');

  for (var i = 0; i < dropDownButtons.length; i++) {
    closeDropDown(dropDownButtons[i]);
  }

  var parentDropDown = e.target.closest('.js-dropdown');
  if (parentDropDown && focus) {
    parentDropDown.querySelector('button').focus();
  }
};

const closeDropDown = element => {
  const dropdownId = element.getAttribute('data-a11y-toggle');
  const dropdown = document.getElementById(dropdownId);

  dropdown.setAttribute('aria-hidden', true);
  element.setAttribute('aria-expanded', false);
};

const closeDropdown = {
  init,
};

export default closeDropdown;
