/* eslint-disable no-magic-numbers */
const initTimer = countdown => {
  const countDownDate = new Date(countdown.dataset.countdownTime);

  const placeHolder = countdown.previousElementSibling;
  const valueElement = countdown.nextElementSibling;

  const daysElement = countdown.querySelector('[data-countdownt-days]');
  const hoursElement = countdown.querySelector('[data-countdownt-hours');
  const minutesElement = countdown.querySelector('[data-countdownt-minutes]');
  const secondsElement = countdown.querySelector('[data-countdownt-seconds]');

  const endTime = Date.parse(countDownDate) / 1000;
  const now = Date.parse(new Date()) / 1000;
  const timeLeft = endTime - now;

  const days = Math.floor(timeLeft / 86400);
  let hours = Math.floor((timeLeft - days * 86400) / 3600);
  let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
  let seconds = Math.floor(
    timeLeft - days * 86400 - hours * 3600 - minutes * 60,
  );

  if (hours < '10') {
    hours = '0' + hours;
  }
  if (minutes < '10') {
    minutes = '0' + minutes;
  }
  if (seconds < '10') {
    seconds = '0' + seconds;
  }

  daysElement.textContent = days;
  hoursElement.textContent = hours;
  minutesElement.textContent = minutes;
  secondsElement.textContent = seconds;

  if (days < 0) {
    if (placeHolder) {
      placeHolder.classList.add('d-none');
      placeHolder.setAttribute('aria-hidden', true);
    }
    if (valueElement) {
      valueElement.classList.remove('d-none');
      valueElement.setAttribute('aria-hidden', false);
    }

    countdown.classList.add('d-none');
    countdown.setAttribute('aria-hidden', true);

  } else {
    if (placeHolder && placeHolder.classList.contains('d-none')) {
      placeHolder.classList.remove('d-none');
      placeHolder.setAttribute('aria-hidden', false);
    }
    if (valueElement) {
      valueElement.classList.add('d-none');
      valueElement.setAttribute('aria-hidden', true);
    }
  }
};

const init = () => {
  const countDownTimers = [...document.querySelectorAll('[data-countdown-time]')];

  countDownTimers.forEach(c => {
    const second = 1000;

    setInterval(function () {
      initTimer(c);
    }, second);
  });
};

const countdownTimer = {
  init,
};

export default countdownTimer;
