import {
  generateId,
  getAdjacentEl,
  KEY_CODES,
  dispatchEvent,
} from './../lib/utils';
const MEGA_MENU = '[data-mega-menu]';
const MEGA_MENU_PREFIX = 'mega-menu__mega-menu-';
const BUTTON_CLS = '.js-menu__link--toggle';
const FADE = {
  IN: 'mega-menu__mega-menu--fade-in',
  OUT: 'mega-menu__mega-menu--fade-out',
};
const KEYFRAMES = {
  FADE_IN: 'mega-menu__megamenu-fadein',
  FADE_OUT: 'mega-menu__megamenu-fadeout',
};

function menu() {
  const menuBarElement = document.querySelector('.mega-menu');
  if (!menuBarElement)
    return;

  const menuBarButtonElements = [
    ...menuBarElement.querySelectorAll(`${BUTTON_CLS}`),
  ];
  let selectedMenuElement = null;

  const setup = () => {
    [...menuBarButtonElements].forEach(el => {
      const id = generateId(el, MEGA_MENU_PREFIX);
      const megaMenuEl = getAdjacentEl(el, MEGA_MENU, 1);
      if (megaMenuEl) {
        el.setAttribute('id', id);
        el.setAttribute('aria-controls', id);
        el.setAttribute('aria-expanded', false);
        el.setAttribute('aria-haspopup', true);

        megaMenuEl.setAttribute('aria-labelledby', id);
        megaMenuEl.setAttribute('aria-hidden', true);
      }
    });
  };

  const setFocus = element => {
    element.focus();
  };

  const getKeyboardFocusableElements = (element = document) => {
    return [
      ...element.querySelectorAll(
        'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
      ),
    ].filter(el => !el.hasAttribute('disabled'));
  };

  const isTabPressed = event =>
    event.key === 'Tab' || event.keyCode === KEY_CODES.TAB_KEY;

  const trapFocus = event => {
    if (
      !(
        isTabPressed(event) ||
        [KEY_CODES.UP_KEY, KEY_CODES.DOWN_KEY].some(
          keyCode => keyCode === event.keyCode,
        )
      )
    )
      return;

    const element = event.currentTarget;
    const focusableElements = getKeyboardFocusableElements(element);
    const index = focusableElements.indexOf(document.activeElement);
    let nextIndex = 0;

    if (event.shiftKey || event.keyCode === KEY_CODES.UP_KEY) {
      event.preventDefault();
      nextIndex = index > 0 ? index - 1 : focusableElements.length - 1;
      focusableElements[nextIndex].focus();
    } else if (isTabPressed(event) || event.keyCode === KEY_CODES.DOWN_KEY) {
      event.preventDefault();
      nextIndex = index + 1 < focusableElements.length ? index + 1 : 0;
      focusableElements[nextIndex].focus();
    }

    event.target.removeEventListener('keydown', trapFocus);
  };

  const handleClickOutside = event => {
    if (
      !menuBarElement.contains(event.target) ||
      event.which === KEY_CODES.ESC_KEY
    ) {
      closeAll(menuBarButtonElements);
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keyup', handleClickOutside);
      setFocus(selectedMenuElement);
    }
  };

  const closeAll = element => {
    [...(Array.isArray(element) ? element : [element])].forEach(el => {
      const megaMenuEl = getAdjacentEl(el, MEGA_MENU, 1);
      toggle(el, megaMenuEl, false);
    });
  };

  const toggle = (element, megaMenuEl, shouldOpen) => {
    element.setAttribute('aria-expanded', shouldOpen);
    megaMenuEl.setAttribute('aria-hidden', !shouldOpen);

    if (shouldOpen) {
      megaMenuEl.classList.add(FADE.IN);
      megaMenuEl.classList.remove(FADE.OUT);
    } else {
      if (megaMenuEl.classList.contains(FADE.IN))
        megaMenuEl.classList.add(FADE.OUT);

      megaMenuEl.classList.remove(FADE.IN);
    }
  };

  const handleAnimationEnded = event => {
    if (event.animationName === KEYFRAMES.FADE_IN) {
      event.target.querySelector('a').focus();
      event.target.addEventListener('keydown', trapFocus);
      event.target.removeEventListener('animationend', handleAnimationEnded);
    }
  };

  const handletoggleMegaMenu = event => {
    if (!event.target.matches(BUTTON_CLS)) return;

    const currentElement = event.target;
    const currentMegaMenuElement = getAdjacentEl(currentElement, MEGA_MENU, 1);

    const shouldOpen =
      currentElement.getAttribute('aria-expanded') === 'false' ? true : false;

    menuBarButtonElements.forEach(element => {
      const megaMenuElement = getAdjacentEl(element, MEGA_MENU, 1);

      toggle(
        element,
        megaMenuElement,
        currentElement !== element ? false : shouldOpen,
      );
    });

    if (shouldOpen && currentElement) {
      selectedMenuElement = currentElement;
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('keyup', handleClickOutside);
    }

    const isKeyboard = event.detail.isKeyboard;

    if (isKeyboard) {
      currentMegaMenuElement.addEventListener(
        'animationend',
        handleAnimationEnded,
      );
    }
  };

  setup();

  const handleKeyboard = event =>
    [KEY_CODES.ENTER_KEY, KEY_CODES.SPACE_KEY].some(
      keyCode => keyCode === event.keyCode,
    )
      ? dispatchEvent(event.target, 'toggleMegaMenu', {
        isKeyboard: true,
      })
      : false;

  const handleMouse = event =>
    event.button === 0
      ? dispatchEvent(event.target, 'toggleMegaMenu', {
        isMouse: true,
      })
      : false;

  menuBarElement.addEventListener('toggleMegaMenu', handletoggleMegaMenu);
  menuBarElement.addEventListener('keydown', handleKeyboard);
  menuBarElement.addEventListener('mouseup', handleMouse);
}

export default menu;
