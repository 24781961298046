const isCustomEventSupported = (() => {
  try {
    var ce = new window.CustomEvent('test', { cancelable: true });
    ce.preventDefault();
    if (ce.defaultPrevented !== true) {
      // IE has problems with .preventDefault() on custom events
      // http://stackoverflow.com/questions/23349191
      throw new Error('Could not prevent default');
    }
    return true;
  } catch (e) {
    return false;
  }
})();

const isElementClosestSupported = (() => {
  return typeof window.Element.prototype.matches === 'function';
})();

// This polyfill adds support for IE10 because Yall-js is using dataset
const isDatasetSupported = (() =>
  document.documentElement.dataset &&
  (Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'dataset') ||
    Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'dataset').get))();

const isNewEventSupported = (() => typeof window.Event === 'function')();

const isIntersectionObserverSupported = (() => {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
      console.debug('Minimal polyfill for edge 15');
      Object.defineProperty(
        window.IntersectionObserverEntry.prototype,
        'isIntersecting',
        {
          get: function () {
            return this.intersectionRatio > 0;
          },
        },
      );
    }
    return true;
  } else {
    return false;
  }
})();

const isMutationObserverSupported = (() =>
  window.MutationObserver ||
  window.WebKitMutationObserver ||
  window.MozMutationObserver)();

export const isObjectFitSupported = (() => {
  var edgeVersion = window.navigator.userAgent.match(/Edge\/(\d{2})\./);
  var validEdgeVersion = 16;
  var edgePartialSupport = edgeVersion
    ? parseInt(edgeVersion[1], 10) >= validEdgeVersion
    : false;

  // Support for object-fit
  var hasSupport = 'objectFit' in document.documentElement.style !== false;
  if (hasSupport && !edgePartialSupport) {
    return true;
  }
  return false;
})();

export const polyfills = [
  !('fetch' in window)
    ? import(/* webpackChunkName: "polyfill-fetch" */ 'whatwg-fetch')
    : null,
  !isCustomEventSupported
    ? import(
        /* webpackChunkName: "polyfill-custom-event" */ 'custom-event-polyfill'
      )
    : null,
  !isElementClosestSupported
    ? import(
        /* webpackChunkName: "polyfill-element-closest" */ 'element-closest/browser'
      )
    : null,
  // Needed for IE10
  !isDatasetSupported
    ? import(/* webpackChunkName: "polyfill-dataset" */ './element-dataset')
    : null,
  !isIntersectionObserverSupported
    ? import(
        /* webpackChunkName: "polyfill-intersection-observer" */ 'intersection-observer'
      )
    : null,
  // Needed for IE10
  !isMutationObserverSupported
    ? import(
        /* webpackChunkName: "polyfill-mutation-observer" */ './mutation-observer'
      )
    : null,
  !isObjectFitSupported
    ? import(/* webpackChunkName: "polyfill-object-fit" */ './object-fit')
    : null,
].filter(Boolean);
