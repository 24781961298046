import '../lib/js-cookie-2.2.0.js';

export const cookieConsent = {
  init() {
    const cookieBanner = document.querySelector('.js-cookiebanner');

    if (cookieBanner && !(Cookies.get('cookieAccepted') && cookieBanner)) {
      cookieBanner.classList.add('show');
      var cookieBannerBtn = document.querySelector('.js-cookie-disclaimer-btn');
      cookieBannerBtn.addEventListener('click', this.onAcceptCookieClick);
    }

    const changeCookieLinks = document.querySelectorAll('.js-cookie-concent-change');
    if (changeCookieLinks.length) {
      changeCookieLinks.forEach(link => {
        link.addEventListener('click', this.onChangeCookies)
      })
    }
  },

  onAcceptCookieClick(e) {
    e.preventDefault();
    Cookies.set('cookieAccepted', 'true', { expires: 365, path: '/' });
    var cookieBanner = document.querySelector('.js-cookiebanner');
    cookieBanner.classList.remove('show');
  },

  onChangeCookies(e) {
    e.preventDefault();
    Cookiebot.renew();
  }
};
