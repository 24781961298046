import { generateId, attrToggle, KEY_CODES } from '../lib/utils';

export function toggleButton({
  toggleClass = '.js-toggle',
  toggleAttr = 'data-toggle',
  togglePrefix = 'toggle-',
} = {}) {
  const toggleButtons = document.querySelectorAll(`[${toggleAttr}]`);
  let activeElement;
  const init = () => {
    toggleButtons.forEach(el => {
      const targetId = el.getAttribute(toggleAttr);
      const target = document.getElementById(targetId);

      if (target) {
        const id = generateId(el, togglePrefix);

        el.setAttribute('id', id);
        el.setAttribute('aria-controls', id);
        el.setAttribute('aria-expanded', false);
        el.setAttribute('aria-haspopup', true);
        target.setAttribute('aria-labelledby', id);
        target.setAttribute('aria-hidden', true);

        el.addEventListener('click', handleToggle);
      }
    });
  };

  const handleClickOutside = event => {
    if (event.which === KEY_CODES.ESC_KEY && activeElement) {
      toggle(activeElement);
      activeElement.focus();
    }
  };

  const toggle = element => {
    const targetId = element.getAttribute(toggleAttr);
    const targetElement = document.getElementById(targetId);
    const bodyElement = document.querySelector('body');
    const headElement = document.querySelector('head');

    bodyElement.classList.toggle('overflow-y-hidden');
    bodyElement.classList.toggle('position-fixed');
    headElement.classList.toggle('overflow-y-hidden');
    headElement.classList.toggle('position-fixed');

    attrToggle(element, 'aria-expanded');
    attrToggle(targetElement, 'aria-hidden');

    const isOpen =
      element.getAttribute('aria-expanded') === 'true' ? true : false;

    if (isOpen) {
      document.addEventListener('keydown', handleClickOutside);
      activeElement = element;
    } else {
      document.removeEventListener('keydown', handleClickOutside);
      var mobileMenuToggles = [...document.querySelectorAll('.m-menu__toggle')];
      if (mobileMenuToggles.length) {
        mobileMenuToggles.forEach(el => {
          el.setAttribute('aria-expanded', false);
        });
      }
    }
  };

  const handleToggle = event => {
    if (!event.target.closest(toggleClass)) return;

    const currentElement = event.target.closest(toggleClass);
    toggle(currentElement);
  };

  if (toggleButtons.length > 0) {
    init();
  }
}
