export const vacancyPageFilter = {
  init() {

    const countryFilterSelector = document.querySelector('.js-vacancies-filter-country');
    if (countryFilterSelector) {
      countryFilterSelector.addEventListener('change', this.handleCountryFilterChange);
    }

    const professionFilterSelector = document.querySelector('.js-vacancies-filter-profession');
    if (professionFilterSelector) {
      professionFilterSelector.addEventListener('change', this.handleProfessionFilterChange);
    }

    const areaFilterSelector = document.querySelector('.js-vacancies-filter-area');
    if (areaFilterSelector) {
      areaFilterSelector.addEventListener('change', this.handleAreaFilterChange);
    }

    const positionFilterSelector = document.querySelector('.js-vacancies-filter-position');
    if (positionFilterSelector) {
      positionFilterSelector.addEventListener('change', this.handlePositionFilterChange);
    }
  },

  handleCountryFilterChange(e) {
    const selectedValue = e.target.value;
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("country", selectedValue);
    window.location.search = searchParams.toString();
  },

  handleProfessionFilterChange(e) {
    const selectedValue = e.target.value;
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("profession", selectedValue);
    window.location.search = searchParams.toString();
  },

  handleAreaFilterChange(e) {
    const selectedValue = e.target.value;
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("area", selectedValue);
    window.location.search = searchParams.toString();
  },

  handlePositionFilterChange(e) {
    const selectedValue = e.target.value;
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("position", selectedValue);
    window.location.search = searchParams.toString();
  }

};
