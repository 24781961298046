export const searchOffices = {
  loader: document.querySelector(".js-loader"),
  query: 'office-search',
  init() {

    const searchInput = document.querySelector(".js-search-auto-complete");
    if (searchInput) {
      searchInput.addEventListener('keyup', this.displayAutoCompleteOptions);
    }

    const officeForm = document.querySelector('.js-search-office-form');
    if (officeForm) {
      officeForm.addEventListener('submit', this.onOfficeFormSubmit);
    }

    const url = window.location.href;
    if (url.includes(searchOffices.query) && officeForm) {
      searchOffices.handleQuery(officeForm);
    }

    const categorySelect = document.querySelector('.js-category-select');
    if (categorySelect) {
      categorySelect.addEventListener('change', this.onCategorySelectChange);
    }

    const officeLookupBtn = document.querySelector('.js-office-lookup');
    if (officeLookupBtn) {
      officeLookupBtn.addEventListener('click', this.onOfficeLookupBtnClick);
    }
  },

  displayAutoCompleteOptions() {
    const searchInput = document.querySelector('.js-search-auto-complete');
    const options = document.querySelector('.js-auto-complete-options');
    const resultsWrapper = document.querySelector('.js-search-autocomplete');
    const officeForm = document.querySelector('.js-search-office-form');

    if (searchInput.value.length > 1) {

      let fixedOptions = options.innerHTML.split('|');
      fixedOptions = fixedOptions.map(option => option.replace('(', '').replace(')', ''));

      let fixedOffices = [];
      fixedOptions.forEach(option => {

        const cutOffLength = 2;
        const splitOption = option.split(' ');
        splitOption.forEach(str => {
          const strLower = str.toLowerCase();

          if (str != "-" && strLower != 'bravida' && strLower != '&amp;' && strLower.length > cutOffLength && strLower.startsWith(searchInput.value.toLowerCase())) {
            fixedOffices.push(str);
          }
        });
      });

      fixedOffices = Array.from(new Set(fixedOffices))

      const searchResults = fixedOffices.filter((item) => item.toLowerCase().includes(searchInput.value.toLowerCase()));
      if (!searchResults.length) {
        resultsWrapper.classList.add('d-none');
      } else {
        resultsWrapper.innerHTML = '';

        const itemsToTake = 15;
        searchResults.slice(0, itemsToTake)
          .forEach((result) => {
            const liItem = document.createElement('li');
            liItem.addEventListener('click', () => {

              if (officeForm) {
                searchInput.value = result;
                searchOffices.showLoader();
                searchOffices.doSearch(officeForm);

                resultsWrapper.classList.add('d-none');
              }
            });

            liItem.innerHTML = result;
            resultsWrapper.appendChild(liItem);
          });

        resultsWrapper.classList.remove('d-none');
      }
    } else {
      resultsWrapper.classList.add('d-none');
    }
  },

  onOfficeFormSubmit(e) {
    e.preventDefault();
    searchOffices.showLoader();
    searchOffices.doSearch(e.target);
  },

  onCategorySelectChange(e) {
    const officeForm = document.querySelector('.js-search-office-form');
    searchOffices.showLoader();
    searchOffices.doSearch(officeForm);
  },

  doSearch(form) {
    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
    })
      .then(res => res.json())
      .then(data => {
        if (data.View) {
          searchOffices.setResultsView(data.View);
        }
        searchOffices.hideLoader();
      });

    searchOffices.pushQuery(form);
  },

  onOfficeLookupBtnClick(e) {
    e.preventDefault();
    searchOffices.showLoader();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(searchOffices.getCurrentPosition, searchOffices.failedToGetPosition);
    }
  },
  failedToGetPosition() {
    const searchError = document.querySelector('.js-officesearcherror');
    searchError.classList.add('officesearcherror--open');

    const officeDiv = document.querySelector('.js-officesearchresult');
    officeDiv.innerHTML = null;
    officeDiv.classList.add('officesearchresult--open');

    searchOffices.hideLoader();
  },
  getCurrentPosition(position) {
    if (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      let url = document.querySelector('.js-find-office-url').value;
      url += `?latitude=${latitude}&longitude=${longitude}`;

      fetch(url, {
        method: 'POST',
      })
        .then(res => res.json())
        .then(data => {
          if (data.View) {
            searchOffices.setResultsView(data.View);
            searchOffices.hideLoader();
          }
        });
    }
  },

  setResultsView(html) {
    const officeDiv = document.querySelector('.js-officesearchresult');
    officeDiv.innerHTML = html;
    officeDiv.classList.remove('officesearchresult--open');

    const searchError = document.querySelector('.js-officesearcherror');
    searchError.classList.remove('officesearcherror--open');

    const timeOut = 50;
    setTimeout(function (e) {
      officeDiv.classList.add('officesearchresult--open');
    }, timeOut);
  },

  pushQuery(form) {
    const inputElement = form.querySelector('#SearchForm_Query');
    const input = inputElement.value;
    const queryString = `?${searchOffices.query}=${input}`;

    window.history.pushState('', '', queryString);
  },

  handleQuery(officeForm) {
    const submitBtn = officeForm.querySelector('[type="submit"]');
    const inputElement = officeForm.querySelector('#SearchForm_Query');
    const urlParams = new URLSearchParams(window.location.search);

    inputElement.value = urlParams.get(searchOffices.query);
    submitBtn.click();
  },
  showLoader() {
    document.querySelector(".loader--container__search-offices").classList.remove("d-none");
    searchOffices.loader.classList.add("loader--loading");
  },
  hideLoader() {
    document.querySelector(".loader--container__search-offices").classList.add("d-none");
    searchOffices.loader.classList.remove("loader--loading");
  }
};
