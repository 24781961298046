import { yall } from '../lib/yall-v3.1.6';

export const newsPagination = {
  init() {
    const paginationBtn = document.querySelector('.js-pagination-btn');
    if (paginationBtn) {
      paginationBtn.addEventListener('click', this.onPaginationBtnClick);
    }
  },

  onPaginationBtnClick(e) {
    let target = e.currentTarget;
    let page = parseInt(target.dataset.page);
    let year = parseInt(target.dataset.year);
    let type = parseInt(target.dataset.type);
    let url = `${target.dataset.pageurl}?p=${page}`;

    if (year > 0 && type > 0) {
      url = `${target.dataset.pageurl}?p=${page}&year=${year}&type=${type}`;
    } else if (year > 0) {
      url = `${target.dataset.pageurl}?p=${page}&year=${year}`;
    } else if (type > 0) {
      url = `${target.dataset.pageurl}?p=${page}&type=${type}`;
    }
    
    const paginationLoader = document.querySelector('.js-pagination-loader');
    paginationLoader.classList.remove('d-none');

    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(response => response.text())
      .then(data => {
        let parsedData = JSON.parse(data);
        if (parsedData.View) {
          page++;
          target.dataset.page = page;
          const resultList = document.querySelector('.js-resultlist');
          resultList.insertAdjacentHTML('beforeend', parsedData.View);
          yall({
            events: {
              load: function (event) {
                const element = event.target;

                if (!element.classList.contains('lazy') && element.nodeName == 'IMG') {
                  element.classList.add('lazy--loaded');
                }
              },
            },
            observeChanges: true,
            lazyClass: 'lazy',
          });
          paginationLoader.classList.add('d-none');

          if (parsedData.HideButton) {
            target.classList.add('d-none');
          }
        } else {
          target.classList.add('d-none');
        }
      })
      .catch(function (err) {
        console.log('Fetch Error: ', err);
      });
  }
};
