export const heatpumprecommendation = {
  heatSteps: [...document.querySelectorAll('.js-heatpumpstep')],
  stepCounter: document.querySelector('.js-stepcounter'),
  topPrevText: document.querySelector('.js-prev-next-step'),
  topPrevArrow: document.querySelector('.js-heat-prev'),
  heatRanges: [...document.querySelectorAll('.js-heatpumprecommendation input[type="range"]')],
  heatNumberInputs: [...document.querySelectorAll('.js-heatpumprecommendation input[type="number"]')],
  heatRadios: [...document.querySelectorAll('.js-heatpumprecommendation input[type="radio"]')],
  resultsBtn: document.querySelector('.js-result-anchor'),
  heatSourceInputs: [...document.querySelectorAll('[name="HeatSource"]')],

  init() {
    var stepAnchors = [...document.querySelectorAll('.js-step-anchor')];
    if (stepAnchors.length && this.topPrevArrow) {
      stepAnchors.forEach(anchor => {
        anchor.addEventListener('click',
          this.onStepAnchorClick);
      });

      this.topPrevArrow.addEventListener('click', this.onPrevArrowClick);

      this.heatRanges.forEach(range => {
        range.addEventListener('input', heatpumprecommendation.onRangeChange);
      });

      this.heatNumberInputs.forEach(input => {
        input.addEventListener('blur', heatpumprecommendation.onNumberInputBlur);
      });

      this.heatRadios.forEach(input => {
        input.addEventListener('change', heatpumprecommendation.onHeatRadiosChange);
      });

      this.resultsBtn.addEventListener('click', heatpumprecommendation.onResultsBtnClick);

      this.heatSourceInputs.forEach(input => {
        input.addEventListener('change', heatpumprecommendation.onHeatSourceInputChange);
      });
    }
  },

  onStepAnchorClick(e) {
    e.preventDefault();
    const currentTarget = e.currentTarget;
    if (currentTarget.classList.contains('disabled'))
      return;
    const targetStep = currentTarget.dataset.step;
    heatpumprecommendation.switchToCorrectStep(targetStep);
  },

  onPrevArrowClick(e) {
    const targetStep = heatpumprecommendation.stepCounter.dataset.currentstep - 1;
    heatpumprecommendation.switchToCorrectStep(targetStep);
  },

  switchToCorrectStep(targetStep) {
    heatpumprecommendation.stepCounter.dataset.currentstep = targetStep;
    heatpumprecommendation.topPrevText.innerText = targetStep;
    heatpumprecommendation.heatSteps.forEach(step => {
      step.dataset.step == targetStep
        ? step.classList.add('heatpumprecommendation__step--current')
        : step.classList.remove('heatpumprecommendation__step--current');
    });

    targetStep > 1
      ? heatpumprecommendation.topPrevArrow.classList.remove('d-none')
      : heatpumprecommendation.topPrevArrow.classList.add('d-none');
  },

  onRangeChange(e) {
    const currentTarget = e.currentTarget;
    const outPut = currentTarget.nextElementSibling;
    const topSibling = currentTarget.previousElementSibling;
    const siblingInput = topSibling.querySelector('input[type="number"]');
    currentTarget.style.setProperty('--val', currentTarget.value);
    outPut.value = currentTarget.value;
    if (siblingInput) {
      siblingInput.value = currentTarget.value;
    }
    heatpumprecommendation.enableNextBtn(e.currentTarget);
  },

  onNumberInputBlur(e) {
    const currentTarget = e.currentTarget;
    const parentContainer = currentTarget.parentElement;
    const rangeInput = parentContainer.nextElementSibling;
    const outPut = currentTarget.nextElementSibling;
    rangeInput.style.setProperty('--val', currentTarget.value);
    rangeInput.value = currentTarget.value;
    outPut.value = currentTarget.value;
    heatpumprecommendation.enableNextBtn(e.currentTarget);
  },

  onHeatRadiosChange(e) {
    heatpumprecommendation.enableNextBtn(e.currentTarget);
  },

  onHeatSourceInputChange(e) {
    const heatSpending = document.querySelector('.js-heat-spending');
    const heatSpendingRange = document.querySelector('.js-heat-spending-range');
    const unit = document.querySelector('.js-unit');
    const solidFuelHelpText = document.querySelector('.js-solidfuel-helptext');
    const currentValue = e.currentTarget.value;
    heatSpending.max = 5000;
    heatSpendingRange.max = 5000;
    solidFuelHelpText.classList.add('d-none');

    if (currentValue === 'pellets') {
      unit.innerText = 'kg';
    } else if (currentValue === 'gas') {
      unit.innerText = 'm3';
    } else if (currentValue === 'solidfuel') {
      unit.innerText = 'Rm';
      heatSpending.max = 22;
      heatSpendingRange.max = 22;
      solidFuelHelpText.classList.remove('d-none');
    } else {
      unit.innerText = 'L';
    }
  },

  enableNextBtn(currentTarget) {
    const parentStep = currentTarget.closest('.js-heatpumpstep');
    const nextStepBtn = parentStep.querySelector('.js-next-anchor');
    nextStepBtn.classList.remove('disabled');
    nextStepBtn.classList.add('button--inverted');
  },

  onResultsBtnClick(e) {
    e.preventDefault();
    const currentTarget = e.currentTarget;

    if (currentTarget.classList.contains('disabled'))
      return;

    const loader = currentTarget.querySelector('.js-loader');
    loader.classList.add('loader--loading');

    const apiUrl = `${window.location.origin}/Api/CalculateHeatPumpRecommendation/`;

    const data = heatpumprecommendation.getInputValues();

    fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'Accept': 'text/html',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.View) {
          const step = 9;
          heatpumprecommendation.switchToCorrectStep(step);

          const resultsView = document.querySelector('.js-resultsstep');
          resultsView.innerHTML = data.View;
          
          loader.classList.remove('loader--loading');

          const contactUsBtn = document.querySelector('.js-contactus-btn');
          contactUsBtn.addEventListener('click', function(e) {
            heatpumprecommendation.onContactBtnClick(e, data.HiddenFieldValue);
          });
        }
      });
  },

  onContactBtnClick(e, hiddenFieldValue) {
    e.preventDefault();

    const formContainer = document.querySelector('.js-resultsform');
    formContainer.classList.remove('d-none');

    const hiddenInputField = formContainer.querySelector('.js-form-hiddenfield');
    hiddenInputField.value = hiddenFieldValue;
  },

  getInputValues() {
    const heatSourceVal =
      document.querySelector('[name="HeatSource"]:checked').value;

    const heatSpendingVal =
      document.querySelector('[name="HeatSpending"]').value;

    const agePercentVal =
      document.querySelector('[name="Age"]:checked').value;

    const peopleInHouseHoldVal =
      document.querySelector('[name="PeopleInHouseHold"]:checked').value;

    const centralHeatingVal =
      document.querySelector('[name="CentralHeating"]:checked').value;

    const heatingAreaVal =
      document.querySelector('[name="HeatingArea"]').value;

    const energySupplementVal =
      document.querySelector('[name="EnergySupplement"]:checked').value;

    const currentBlockIdVal =
      document.querySelector('.js-currentBlockId').value;

    return {
      'HeatSource': heatSourceVal,
      'HeatSpending': heatSpendingVal,
      'AgeValuePercent': agePercentVal,
      'NumberOfPeopleInHouseHold': peopleInHouseHoldVal,
      'CentralHeating': centralHeatingVal,
      'HeatingArea': heatingAreaVal,
      'EnergySupplement': energySupplementVal,
      'CurrentBlockId': currentBlockIdVal
    }
  }
};
