const videoEl = document.querySelector('.js-hero-video');
const sourceEl = videoEl?.querySelector('source');
const videoSrc = videoEl?.dataset.videoSrc;
const videoType = videoEl?.dataset.videoType;
const mobileVideoSrc = videoEl?.dataset.mobileVideoSrc;
const mobileVideoType = videoEl?.dataset.mobileVideoType;
const mobileImageNoMobileVideo = document.querySelector('.js-mobile-hero-image-no-mobile-video');
const playToggleBtn = document.querySelector('.js-hero-toggle-play');
const playLabel = playToggleBtn?.querySelector('.js-hero-toggle-lable');
const playLabelText = playToggleBtn?.getAttribute('data-video-play')
const pauseLabelText = playToggleBtn?.getAttribute('data-video-pause')
const playDisabled = () => localStorage.getItem('play-disabled');
const isDesktop = () => window.innerWidth >= LgViewPort;
const LgViewPort = 768;
let desktopVideoIsShown = false;

const loadVideo = () => {
  videoEl.load();

  if (!playDisabled()) {
    playVideo();
    playToggleBtnPause()
  } else {
    playToggleBtnPlay();
  }
}

const playVideo = () => {
  videoEl.play();
}

const pauseVideo = () => {
  videoEl.pause();
}

const setSourceAttr = (src, type) => {
  sourceEl.setAttribute('src', src);
  sourceEl.setAttribute('type', type);
}

const setSourceAttrAndLoadVideo = (src, type) => {
  setSourceAttr(src, type);
  loadVideo();
}

const handlePlayToggleClick = () => {
  if (playDisabled()) {
    localStorage.removeItem('play-disabled');
    playVideo();
    playToggleBtnPause();
  } else {
    localStorage.setItem('play-disabled', 'true');
    pauseVideo();
    playToggleBtnPlay();
  }
}

const playToggleBtnPause = () => {
  if (playToggleBtn) {
    playToggleBtn.setAttribute('aria-pressed', 'true');
    playLabel.textContent = pauseLabelText;
  }
}

const playToggleBtnPlay = () => {
  if (playToggleBtn) {
    playToggleBtn.setAttribute('aria-pressed', 'false');
    playLabel.textContent = playLabelText;
  }
}

export const init = () => {

  if (videoEl && sourceEl) {

    if (isDesktop() && videoSrc && videoType) {
      setSourceAttrAndLoadVideo(videoSrc, videoType);
      desktopVideoIsShown = true;
    }

    if (!isDesktop() && mobileVideoSrc && mobileVideoType) {
      setSourceAttrAndLoadVideo(mobileVideoSrc, mobileVideoType);

    } else if (!isDesktop() && videoSrc && videoType && !mobileImageNoMobileVideo) {
      setSourceAttrAndLoadVideo(videoSrc, videoType);
    }

    window.addEventListener('resize', function () {

      if (desktopVideoIsShown && !isDesktop() && mobileVideoSrc && mobileVideoType) {
        setSourceAttrAndLoadVideo(mobileVideoSrc, mobileVideoType);
        desktopVideoIsShown = false;
      }

      if (!desktopVideoIsShown && isDesktop() && videoSrc && videoType) {
        setSourceAttrAndLoadVideo(videoSrc, videoType);
        desktopVideoIsShown = true;
      }
    });

    if (playToggleBtn) {
      playToggleBtn.addEventListener('click', handlePlayToggleClick);
    }
  }
}

export default init;
