export const newsPressFilter = {
  init() {

    const yearFilterSelector = document.querySelector('.js-news-filter-year');

    if (yearFilterSelector) {
      yearFilterSelector.addEventListener('change', this.handleYearFilterChange);
    }

    const typeFilterSelector = document.querySelector('.js-news-filter-type');

    if (typeFilterSelector) {
      typeFilterSelector.addEventListener('change', this.handleTypeFilterChange);
    }
  },

  handleYearFilterChange(e) {
    const selectedValue = e.target.value;
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("year", selectedValue);
    window.location.search = searchParams.toString();

  },

  handleTypeFilterChange(e) {
    const selectedValue = e.target.value;
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("type", selectedValue);
    window.location.search = searchParams.toString();

  }
};
