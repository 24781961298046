export const sideMenuButton = {
  init() {
    const sideMenuButton = document.querySelector('.js-side-menu-button');

    if (sideMenuButton != undefined) {
      sideMenuButton.addEventListener('click', this.sideMenuBtnClick);
    }
  },

  sideMenuBtnClick(e) {
    const articleNav = document.querySelector(".js-side-menu-nav");

    if (articleNav != undefined) {
      articleNav.classList.toggle("d-none");
      e.srcElement.innerText = articleNav.classList.contains('d-none') ? e.srcElement.dataset.show : e.srcElement.dataset.hide;
    }
  }
};
