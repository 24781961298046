export const generateId = (element, prefix, numberOfChars = 4) => {
  let id =
    element.id ||
    (element.name && `${element.name}-${generateChars(numberOfChars)}`) ||
    generateChars(numberOfChars);
  id = id.replace(/(:|\.|\[|\]|,)/g, '');

  return `${prefix}-${id}`;
};

export const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);

const randomNumber = 36;
export const generateChars = length =>
  Array.from({ length }, () =>
    getRandomNumber(0, randomNumber).toString(randomNumber),
  ).join('');

export const getAdjacentEl = (startEl, selector, direction = 1) => {
  const prop = `${direction > 0 ? 'next' : 'previous'}ElementSibling`;

  let sibling = startEl[prop];
  while (sibling) {
    if (sibling.matches(selector)) {
      return sibling;
    }
    sibling = sibling[prop];
  }

  return sibling;
};

export const attrToggle = (el, attr) => {
  el.getAttribute(attr) == 'false'
    ? el.setAttribute(attr, 'true')
    : el.setAttribute(attr, 'false');
};

export const dispatchEvent = (element, type, customArgs) => {
  const event = new CustomEvent(type, {
    detail: customArgs,
    bubbles: true,
    cancelable: true,
  });

  return element.dispatchEvent(event);
};

export const KEY_CODES = {
  BACK_KEY: 46,
  DELETE_KEY: 8,
  ENTER_KEY: 13,
  A_KEY: 65,
  ESC_KEY: 27,
  UP_KEY: 38,
  RIGHT_KEY: 39,
  DOWN_KEY: 40,
  LEFT_KEY: 37,
  PAGE_UP_KEY: 33,
  PAGE_DOWN_KEY: 34,
  SPACE_KEY: 32,
  TAB_KEY: 9,
};

export const createElement = options => {
  let el;
  let a;
  let i;

  if (!options.tagName) {
    el = document.createDocumentFragment();
  } else {
    el = document.createElement(options.tagName);
    if (options.className) {
      el.className = options.className;
    }

    if (options.attributes) {
      for (a in options.attributes) {
        el.setAttribute(a, options.attributes[a]);
      }
    }

    if (options.html !== undefined) {
      el.innerHTML = options.html;
    }
  }

  if (options.text) {
    el.appendChild(document.createTextNode(options.text));
  }

  // IE 8 doesn"t have HTMLElement
  if (window.HTMLElement === undefined) {
    window.HTMLElement = Element;
  }

  if (options.childs && options.childs.length) {
    for (i = 0; i < options.childs.length; i++) {
      el.appendChild(
        options.childs[i] instanceof window.HTMLElement
          ? options.childs[i]
          : createElement(options.childs[i]),
      );
    }
  }

  return el;
};

export const strToEl = (function () {
  const tmpEl = document.createElement('div');
  return function (str) {
    const cleanedInput = str.trim();
    let r;
    tmpEl.innerHTML = cleanedInput;
    r = tmpEl.children[0];

    while (tmpEl.firstChild) {
      tmpEl.removeChild(tmpEl.firstChild);
    }

    return r;
  };
})();
