import { generateId, attrToggle, createElement, strToEl } from '../lib/utils';

const loaderTemplate = createElement({
  tagName: 'div',
  className: 'm-menu-loader',
  childs: [
    createElement({
      tagName: 'div',
      className: 'm-menu-loader__inner',
    }),
  ],
});

const loaderTemplateCircle = createElement({
  tagName: 'div',
  className: 'm-menu-loader__circle',
});

export default function mobileMenu() {
  const API_URL = `${window.location.origin}/Api/GetMenu/`;
  const bodyElement = document.querySelector('body');
  const currentPageId = bodyElement.getAttribute('data-currentpageid');
  const lang = bodyElement.getAttribute('data-lang');
  const targetDevice = window.matchMedia('screen and (max-width: 1023px)');
  const mobileMenuButton = document.querySelector('.js-menu-button');
  const menuWrapperElement = document.querySelector('.js-menuwrapper');

  const MENU_PREFIX = 'm-menu-';
  const menuClass = '.m-menu';
  const menuToogleClass = '.js-menu__toggle';
  const menuHasChildrenClass = '.m-menu__item--has-children';
  const menuIsOpen = 'm-menu__item--is-open';
  const menuCurrentLink = 'm-menu__link--current';

  const loaderClass = '.m-menu-loader';
  const loaderInnerClass = '.m-menu-loader__inner';
  const loaderShow = 'm-menu-loader__inner--show';

  const init = htmlMenuString => {
    const menu = strToEl(htmlMenuString);
    const toggles = [...menu.querySelectorAll(menuToogleClass)];

    toggles.forEach(el => {
      const target = el.nextElementSibling;
      if (target) {
        const id = generateId(el, MENU_PREFIX);
        const targetId = generateId(target, MENU_PREFIX);

        el.setAttribute('id', id);
        el.setAttribute('aria-controls', targetId);
        el.setAttribute('aria-expanded', false);
        el.setAttribute('aria-haspopup', true);
        target.setAttribute('id', targetId);
        target.setAttribute('aria-labelledby', id);
        target.setAttribute('aria-hidden', true);
      }
    });

    const currentPageLink = menu.querySelector(
      `[data-current-node="${currentPageId}"]`,
    );

    if (currentPageLink) currentPageLink.classList.add(menuCurrentLink);

    var all = toggles.reduce((acc, val) => {
      if (
        val.nextElementSibling.querySelector(
          `[data-current-node="${currentPageId}"]`,
        )
      )
        acc.push(val);
      return acc;
    }, []);

    [...all].forEach(el => {
      const target = el.nextElementSibling;
      el.setAttribute('aria-expanded', true);
      target.setAttribute('aria-hidden', false);

      const closestByCurrentElement = el.closest(menuHasChildrenClass);
      if (closestByCurrentElement)
        closestByCurrentElement.classList.add(menuIsOpen);
    });

    menu.addEventListener('click', handleToggle);
    menuWrapperElement.insertBefore(menu, menuWrapperElement.firstChild);
  };

  const handleToggle = event => {
    var target = event.target;
    if (!!target.closest(menuClass)) event.stopPropagation();
    else return;

    if (!target.closest(menuToogleClass)) return;

    const currentElement = target.classList.contains('m-menu__toggle--back')
      ? document.querySelector(`[data-targetid="${target.dataset.target}"]`)
      : target;
    const targetElement = currentElement.nextElementSibling;
    const closestByCurrentElement = currentElement.closest(
      menuHasChildrenClass,
    );

    attrToggle(currentElement, 'aria-expanded');
    attrToggle(targetElement, 'aria-hidden');

    handleMenuScroll(target, currentElement, targetElement);

    const isOpen =
      currentElement.getAttribute('aria-expanded') === 'true' ? true : false;

    isOpen
      ? closestByCurrentElement.classList.add(menuIsOpen)
      : closestByCurrentElement.classList.remove(menuIsOpen);

    const mMenu = document.querySelector(menuClass);
    mMenu.scroll(0, 0);
  };

  const handleLoadMenu = _ => {
    loadMenu(currentPageId, lang);
  };

  const showLoader = () => {
    loaderTemplate.appendChild(loaderTemplateCircle);
    const loaderInnerElement = loaderTemplate.querySelector(loaderInnerClass);
    loaderInnerElement.appendChild(loaderTemplateCircle);
    loaderInnerElement.classList.add(loaderShow);
    menuWrapperElement.appendChild(loaderTemplate);
  };

  const hideLoader = () => {
    const loaderElement = document.querySelector(loaderClass);
    loaderElement.parentNode.removeChild(loaderElement);
  };

  const loadMenu = (currentPageId, lang) => {
    mobileMenuButton.removeEventListener('click', handleLoadMenu);
    showLoader();

    fetch(`${API_URL}?currentPageId=${currentPageId}&lang=${lang}`, {
      credentials: 'include',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw Error('Unsuccessful response');
        }

        return response.text().then(data => {
          const result = JSON.parse(data);
          if (result.Success) {
            init(result.View);
          }
        });
      })
      .catch(error => {
        console.warn(error);
        mobileMenuButton.addEventListener('click', handleLoadMenu);
      })
      .finally(() => {
        mobileMenuButton.removeEventListener('click', handleLoadMenu);
        targetDevice.removeListener(handleDeviceChange);
        hideLoader();
      });
  };

  const handleDeviceChange = event => {
    if (event.matches) {
      // If desktop menu is open and resizing window to small  device load mobile menu
      if (menuWrapperElement.getAttribute('aria-hidden') === 'false') {
        loadMenu(currentPageId, lang);
        return false;
      }

      mobileMenuButton.addEventListener('click', handleLoadMenu);
      return true;
    } else {
      mobileMenuButton.removeEventListener('click', handleLoadMenu);
      return false;
    }
  };

  const handleMenuScroll = (target, currentElement, targetElement) => {
    const menu = currentElement.closest(menuClass);
    const parentList = currentElement.closest('.js-menu-list');
    const windowHeight = window.innerHeight;
    const header = target.closest('header');
    const headerHeight = header.clientHeight;
    const languageSelectHeight = header.querySelector('.js-language-mobile').clientHeight;
    const listItemHeight = menu.querySelector('.js-menu-item').clientHeight;
    const noScrollChildrenCount = Math.floor((windowHeight - (headerHeight + languageSelectHeight)) / listItemHeight);

    menu.style.overflowY = null;

    if (target.classList.contains('m-menu__toggle--back')) {
      if (parentList && parentList.children.length <= noScrollChildrenCount) {
        menu.style.overflowY = 'hidden';
      }
    } else if (targetElement.children.length <= noScrollChildrenCount) {
      menu.style.overflowY = 'hidden';
    } else {
      targetElement.style.minHeight = `${menu.scrollHeight}px`;
    }
  }

  targetDevice.addListener(handleDeviceChange);
  handleDeviceChange(targetDevice);
}
