export const accordion = {
  init() {
    const accordionBtns =
      [... document.querySelectorAll('.js-accordion-button')];

    if (accordionBtns.length) {
      accordionBtns.forEach(btn => {
        btn.addEventListener('click', this.onAccordionBtnClick);
      });
    }
  },
  onAccordionBtnClick(e) {
    var parentAccordion = e.target.closest('.js-accordion');
    parentAccordion.classList.add('accordion--open');
  }
};
